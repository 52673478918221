import styled from "styled-components";
import { Link } from 'gatsby'

export const PortfolioContainer = styled.div`
    width: 60%;
    margin: 20px auto;
    line-height: 1.8;

    h1 {
        text-align: left;       
        font-size: 35px;
    }

    @media (max-width: 800px) {
        width: 100%; 
    }
`;

export const ProjectImage = styled.div`
    display: block;
    height: 400px;
    width: 100%;
    border-radius: 5px;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    margin-bottom: 5px;
    transition-duration: 0.3s;

    @media screen and (max-width: 800px) {
        background-image: url(${props => props.mobileSrc});
    }
`;

export const ProjectButton = styled.div`
    display: inline;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s;

    :hover {
        border-bottom: 2px solid black;
    }
`;

export const Project = styled.div`
    display: flex;
    flex-direction: column;
    margin: 50px 10px;
    transition-duration: 0.3s;

    :hover {
        ${ProjectImage} {
            transform: scale(1.03);
        }

        ${ProjectButton} {
            border-bottom: 2px solid black;
        }
    }
`;

export const ProjectLink = styled(Link)`
    color: black;
    text-decoration: none;
`;

export const NextProject = styled.div`
    display: flex;
    flex-direction: column;
    margin: 50px 10px;
    transition-duration: 0.3s;

    h3 {
        color: #BBB;
        margin: 0 auto;
    }

    p {
        margin: 10px auto;
    }
`;