import React from "react";

import Layout from '../components/layout';
import Head from '../components/head';

import ForecastCover from '../Images/Forecast_Cover.png';
import ForecastMobile from '../Images/Forecast_Mobile.png';
import FlowerCakeCover from '../Images/FlowerCakeCover.png';
import FlowerCakeMobile from '../Images/FlowerCakeMobile.png';

import {
    PortfolioContainer,
    Project,
    ProjectImage,
    ProjectLink,
    ProjectButton,
    NextProject
} from '../Styles/Portfolio.style';

const Portfolio = () => {
    return (
        <Layout>
            <Head title="Portfolio" />
            <PortfolioContainer>
                <h1>What I've been working on</h1>
                <p>I like to stay busy and always have a project in my hands. <br />
                Take a look at some of the applications I've dedicated my time to.</p>
                <Project>
                    <ProjectLink to="/Forecast"> 
                        <h2>Forecast</h2>
                        <ProjectImage src={ForecastCover} mobileSrc={ForecastMobile} alt=''/>              
                        <p>A 5 day weather application that sets weather data based on user input.</p>              
                        <ProjectButton> View Project <span>&#8250;</span></ProjectButton>
                    </ProjectLink>
                </Project>
                <Project>
                    <ProjectLink to="/FlowerCake"> 
                        <h2>Flower Cake</h2>
                        <ProjectImage src={FlowerCakeCover} mobileSrc={FlowerCakeMobile} alt=''/>              
                        <p>An e-commerce application built with React for cakes shop</p>              
                        <ProjectButton> View Project <span>&#8250;</span></ProjectButton>
                    </ProjectLink>
                </Project>
            </PortfolioContainer>
        </Layout>
    );
}

export default Portfolio;